(function ($) {
  Drupal.behaviors.contentBlockLargeV1 = {
    attach: function (context) {
      var $modules = $('.js-content-block', context);

      $modules.each(function (index) {
        var $module = $(this);
        var $leadIn = $('.js-lead-in', $module);

        // Allow btn to launch overlay
        $('.js-overlay', $module).off('click.openOverlay').on('click.openOverlay', function (e) {
          e.preventDefault();
          var $overlayContent = $('.js-overlay-content', $module);

          generic.overlay.launch({
            'content': $overlayContent.html(),
            height: 304,
            width: 668,
            maxWidth: '100%',
            cssClass: 'overlay-content',
            scrolling: true,
            escKey: true
          });
        });

        // Slide to next module
        $leadIn.off('click.leadIn').on('click.leadIn', function (event) {
          event.preventDefault();
          // Without propagation there is a flicker if clickable content block
          event.stopPropagation();
          var anchorTo = $(this).attr('href');

          // Determine if anchorTo is an element, otherwise scroll to the next content block
          try {
            var $scrollTo = $(anchorTo);

            $('html, body').animate({
              scrollTop: $scrollTo.offset().top
            }, 'slow');
          } catch (e) {
            console.log(e);
            var $element = false;

            if ($modules.eq(index + 1).length) {
              $element = $modules.eq(index + 1);
            } else {
              $element = $('.site-content').children().last();
            }
            $('html, body').animate({
              scrollTop: $element.offset().top
            }, 'slow');
          }
        });
      });

      $(document).on('cbox_complete', function () {
        $('.js-basic-overlay-cta').on('click', function () {
          generic.overlay.hide();
        });
      });
    }
  };
})(jQuery);
